import {
  Button,
  Link,
  makeStyles,
  TextField,
  Typography,
} from '@material-ui/core';
import { useFormik } from 'formik';
import { useLogin, useNotify } from 'ra-core';
import React from 'react';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';
import { setLoginPageToForgotPassword, setLoginPageToRegister } from '../../../state/actions';

const styles = makeStyles({
  form: {
    display: 'flex',
    flexDirection: 'column',

    '& > * + *': {
      marginTop: '12px',
    },
  },
  forgotPassword: {
    textAlign: 'right',
  },
  loginButton: {
    marginTop: '24px',
  },
  register: {
    marginTop: '24px',
    textAlign: 'center',
  },
});

const validationSchema = yup.object().shape({
  email: yup.string().email('Must be a valid email').required('Email is required'),
  password: yup.string().required('Password is required'),
});

/* eslint-disable jsx-a11y/anchor-is-valid */
// Link components with a component="button" will use button

const LoginPage: React.FunctionComponent = () => {
  const login = useLogin();
  const notify = useNotify();
  const classes = styles();
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    onSubmit: (values) => {
      login({ username: values.email, password: values.password })
        .catch(() => notify('Invalid email or password'));
    },
    validationSchema,
  });

  return (
    <>
      <form className={classes.form} onSubmit={formik.handleSubmit}>
        <TextField
          label="Email"
          value={formik.values.email}
          onChange={formik.handleChange('email')}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
        />
        <TextField
          label="Password"
          type="password"
          value={formik.values.password}
          onChange={formik.handleChange('password')}
          error={formik.touched.password && Boolean(formik.errors.password)}
          helperText={formik.touched.password && formik.errors.password}
        />
        <Link
          className={classes.forgotPassword}
          type="button"
          component="button"
          variant="body2"
          onClick={() => dispatch(setLoginPageToForgotPassword())}
        >
          Forgot password?
        </Link>
        <Button className={classes.loginButton} variant="contained" color="primary" type="submit">
          Login
        </Button>
      </form>
      <Typography className={classes.register} variant="body2">
        Don&apos;t have an account?&nbsp;
        <Link
          component="button"
          onClick={() => dispatch(setLoginPageToRegister())}
        >
          Register
        </Link>
      </Typography>
    </>
  );
};

export default LoginPage;
