import {
  Button, Link, makeStyles, TextField, Typography,
} from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { useFormik } from 'formik';
import React from 'react';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';
import { forgotPassword } from '../../../services/auth';
import {
  setLoginPageToVerifyForgotPassword,
  setLoginPageToLogin,
  setLoginPageToRegister,
  setLoginPageEmail,
} from '../../../state/actions';

const styles = makeStyles({
  form: {
    display: 'flex',
    flexDirection: 'column',

    '& > * + *': {
      marginTop: '12px',
    },
  },
  button: {
    marginTop: '24px',
  },
  goBack: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: '24px',
  },
});

const validationSchema = yup.object().shape({
  email: yup.string().email('Must be a valid email').required('Email is required'),
});

/* eslint-disable jsx-a11y/anchor-is-valid */
// Link components with a component="button" will use button

const ForgotPasswordPage: React.FunctionComponent = () => {
  const dispatch = useDispatch();
  const classes = styles();
  const formik = useFormik({
    initialValues: {
      email: '',
    },
    onSubmit: async (values) => {
      await forgotPassword(values.email);
      dispatch(setLoginPageEmail(values.email));
      dispatch(setLoginPageToVerifyForgotPassword());
    },
    validationSchema,
  });

  return (
    <form className={classes.form} onSubmit={formik.handleSubmit}>
      <Typography variant="h5">Forgot your password?</Typography>
      <Typography variant="body2">
        If you forgot your password you can enter your
        email account and get a verification code sent to your email

        If you don&apos;t have an account you can&nbsp;
        <Link
          variant="body2"
          component="button"
          type="button"
          onClick={() => dispatch(setLoginPageToRegister())}
        >
          create one
        </Link>
      </Typography>
      <TextField
        label="Email"
        value={formik.values.email}
        onChange={formik.handleChange('email')}
        error={formik.touched.email && Boolean(formik.errors.email)}
        helperText={formik.touched.email && formik.errors.email}
      />
      <Button className={classes.button} variant="contained" color="primary" type="submit">
        Send verification code
      </Button>
      <Link
        className={classes.goBack}
        variant="body2"
        component="button"
        type="button"
        onClick={() => dispatch(setLoginPageToLogin())}
      >
        <ArrowBackIosIcon fontSize="small" />
        Back to login
      </Link>
    </form>
  );
};

export default ForgotPasswordPage;
