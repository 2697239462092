import React, { useState } from 'react';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { makeStyles } from '@material-ui/core';
import { GameServerResponse } from '../../types/serverResponse';
import { CustomFieldProps } from '../commonTypes';
import { centerAlignSideFlexStyle } from '../commonStyles';

const serverPasswordFieldStyles = makeStyles({
  noStyleButton: {
    background: 'none',
    border: 'none',
    padding: 'none',
    margin: 'none',
  },
});

const ServerPasswordField: React.FunctionComponent<CustomFieldProps> = ({
  record,
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const useSideFlex = centerAlignSideFlexStyle();
  const useStyles = serverPasswordFieldStyles();

  const { password } = (record as GameServerResponse).settings;
  const hidePassword = (inputPassword: string) => (Array(inputPassword.length).join('•'));

  return (
    <p className={useSideFlex.centerAlignSideFlex}>
      <span>{showPassword ? password : hidePassword(password)}</span>
      <button
        type="button"
        className={useStyles.noStyleButton}
        onClick={() => { setShowPassword(!showPassword); }}
      >
        {showPassword ? <VisibilityOffIcon color="action" /> : <VisibilityIcon color="action" /> }
      </button>
    </p>
  );
};

export default ServerPasswordField;
