import React from 'react';
import { makeStyles } from '@material-ui/core';
import { GameServerResponse } from '../../types/serverResponse';
import { CustomFieldProps } from '../commonTypes';
import { centerAlignSideFlexStyle } from '../commonStyles';

const statusCircleStyles = makeStyles({
  circle: {
    height: '18px',
    width: '18px',
    display: 'inline-block',
    borderRadius: '50%',
    backgroundColor: 'grey',
  },
  circleGreen: {
    backgroundColor: '#29bf29',
  },
  circleYellow: {
    backgroundColor: '#efcb32',
  },
});

const ServerStatusField: React.FunctionComponent<CustomFieldProps> = ({
  record,
}) => {
  const useSideFlex = centerAlignSideFlexStyle();
  const classes = statusCircleStyles();

  const renderIndicator = (text: string, colorClass?: string) => (
    <p className={useSideFlex.centerAlignSideFlex}>
      <span className={`${classes.circle} ${colorClass}`} />
      <span>{text}</span>
    </p>
  );

  if (!record) {
    return renderIndicator('Off');
  }

  switch ((record as GameServerResponse).status) {
    case 'running':
      return renderIndicator('Running', classes.circleGreen);
    case 'shutting-down':
      return renderIndicator('Shutting down', classes.circleYellow);
    case 'starting':
      return renderIndicator('Starting', classes.circleYellow);
    case 'off':
    default:
      return renderIndicator('Off');
  }
};

export default ServerStatusField;
