import React from 'react';
import {
  Button,
  Link,
  makeStyles,
  TextField,
  Typography,
} from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';
import { useNotify } from 'ra-core';

import { setLoginPageToLogin } from '../../../state/actions';
import { resetForgottenPassword } from '../../../services/auth';
import { useCustomLogin } from '../../../state/selectors';

const styles = makeStyles({
  form: {
    display: 'flex',
    flexDirection: 'column',
    '& > * + *': {
      marginTop: '12px',
    },
  },
  button: {
    marginTop: '24px',
  },
  goBack: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: '24px',
  },
});

const validationSchema = yup.object().shape({
  verificationCode: yup
    .string()
    .min(4, 'Validation code is required')
    .required('Validation code is required'),
  password: yup
    .string()
    .min(8, 'Password should be longer than 8 characters')
    .matches(/[a-z]/, 'Password should contain at least one lowercase letter')
    .matches(/[A-Z]/, 'Password should contain at least one uppercase letter')
    .matches(/\d/, 'Password should contain at least one number')
    .required('Password is requried'),
});

/* eslint-disable jsx-a11y/anchor-is-valid */
// Link components with a component="button" will use button
const ForgotPasswordVerifyPage: React.FunctionComponent = () => {
  const classes = styles();
  const customLogin = useCustomLogin();
  const notify = useNotify();
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      verificationCode: '',
      password: '',
    },
    onSubmit: async (values) => {
      try {
        await resetForgottenPassword(customLogin.email, values.password, values.verificationCode);
        dispatch(setLoginPageToLogin());
      } catch (error) {
        notify(error.message);
      }
    },
    validationSchema,
  });

  return (
    <form className={classes.form} onSubmit={formik.handleSubmit}>
      <Typography variant="h5">Reset your password</Typography>
      <Typography variant="body2">
        An email with a verification code has been sent to
        the specified email address if you have an account
      </Typography>
      <TextField
        label="Validation code"
        value={formik.values.verificationCode}
        onChange={formik.handleChange('verificationCode')}
        error={formik.touched.verificationCode && Boolean(formik.errors.verificationCode)}
        helperText={formik.touched.verificationCode && formik.errors.verificationCode}
      />
      <TextField
        label="New password"
        type="password"
        value={formik.values.password}
        onChange={formik.handleChange('password')}
        error={formik.touched.password && Boolean(formik.errors.password)}
        helperText={formik.touched.password && formik.errors.password}
      />
      <Button className={classes.button} variant="contained" color="primary" type="submit">
        Submit
      </Button>
      <Link
        className={classes.goBack}
        variant="body2"
        component="button"
        type="button"
        onClick={() => dispatch(setLoginPageToLogin())}
      >
        <ArrowBackIosIcon fontSize="small" />
        Back to login
      </Link>
    </form>
  );
};

export default ForgotPasswordVerifyPage;
