import { authenticate } from '../services/auth';

interface AuthProviderLoginParams {
  username: string;
  password: string
}

const useMockLogin = process.env.MOCK_SERVER_LOGIN === 'true' || false;

export async function login(params: AuthProviderLoginParams): Promise<void> {
  if (useMockLogin) {
    const userName = params.username;

    const mockJwt = {
      username: userName,
    };

    localStorage.setItem('token', btoa(JSON.stringify(mockJwt)));
  } else {
    const userSession = await authenticate(params.username, params.password);
    localStorage.setItem('token', userSession.getAccessToken().getJwtToken());
  }
}

export function checkAuth(): Promise<void> {
  if (localStorage.getItem('token')) {
    return Promise.resolve();
  }
  return Promise.reject();
}

// keep paramenter for future use when this method is implemented
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const checkError = (error: Error) => Promise.resolve();
export const logout = () => {
  localStorage.removeItem('token');
  return Promise.resolve();
};
// keep paramenter for future use when this method is implemented
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const getPermissions = (params: any) => Promise.resolve();

export default {
  login,
  checkAuth,
  checkError,
  logout,
  getPermissions,
};
