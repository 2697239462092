import {
  AuthenticationDetails,
  CognitoUserAttribute,
  CognitoUserSession,
  ISignUpResult,
} from 'amazon-cognito-identity-js';
import { getCognitoUser, getCognitoUserPool } from './cognitoResources';

export function signUp(email: string, password: string): Promise<ISignUpResult> {
  const userPool = getCognitoUserPool();

  // Should be removed, nickname is not mandatory
  // Also causes test to fail
  const dataNickname = new CognitoUserAttribute({
    Name: 'nickname',
    Value: email,
  });

  return new Promise((resolve, reject) => {
    userPool.signUp(email, password, [/* remove */dataNickname/* me */], null as any,
      (err, result) => {
        if (err) {
          reject(err);
        }

        if (result) {
          resolve(result);
        }
      });
  });
}

export function verify(email: string, verificationCode: string): Promise<void> {
  return new Promise((resolve, reject) => {
    const userPool = getCognitoUserPool();
    const cognitoUser = getCognitoUser(userPool, email);

    cognitoUser.confirmRegistration(verificationCode, true, (err) => {
      if (err) {
        reject(err);
      } else {
        resolve();
      }
    });
  });
}

export function authenticate(email: string, password: string): Promise<CognitoUserSession> {
  return new Promise((resolve, reject) => {
    const authenticationDetails = new AuthenticationDetails({
      Username: email,
      Password: password,
    });
    const cognitoPool = getCognitoUserPool();
    const cognitoUser = getCognitoUser(cognitoPool, email);

    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: (userSession) => resolve(userSession),
      onFailure: (err: Error) => reject(err),
    });
  });
}

export function forgotPassword(email: string): Promise<void> {
  return new Promise((resolve, reject) => {
    const cognitoPool = getCognitoUserPool();
    const cognitoUser = getCognitoUser(cognitoPool, email);

    cognitoUser.forgotPassword({
      onSuccess: () => resolve(),
      onFailure: (err: Error) => reject(err),
    });
  });
}

export function resetForgottenPassword(
  email: string,
  newPassword: string,
  verificationCode: string,
): Promise<void> {
  return new Promise((resolve, reject) => {
    const cognitoPool = getCognitoUserPool();
    const cognitoUser = getCognitoUser(cognitoPool, email);

    cognitoUser.confirmPassword(verificationCode, newPassword, {
      onSuccess: () => resolve(),
      onFailure: (err) => reject(err),
    });
  });
}
