import React from 'react';
import {
  fetchUtils, Admin, Resource, ListGuesser,
} from 'react-admin';
import PaymentIcon from '@material-ui/icons/Payment';
import StorageIcon from '@material-ui/icons/Storage';
import simpleRestProvider from 'ra-data-simple-rest';

import authProvider from './providers/authProviders';
import ServerList from './components/ServerList';
import ServerEdit from './components/ServerEdit';
import ServerCreate from './components/ServerCreate';
import Menu from './components/Menu';
import { loginPageReducer } from './state/reducers';
import LoginRouter from './components/pages/LoginRouter';

const httpClient = (url: string, options: any = {}) => {
  if (!options.headers) {
    // eslint-disable-next-line no-param-reassign
    options.headers = new Headers({ Accept: 'application/json' });
  }
  options.headers.set('Authorization', localStorage.getItem('token'));
  return fetchUtils.fetchJson(url, options);
};

const serverUrl = `${process.env.SERVER_URL}/user`;
const dataProvider = simpleRestProvider(serverUrl, httpClient);

const App: React.FunctionComponent = () => (
  <Admin
    authProvider={authProvider}
    customReducers={{
      loginPage: loginPageReducer,
    }}
    menu={Menu}
    dataProvider={dataProvider}
    loginPage={LoginRouter}
  >
    <Resource
      name="servers"
      icon={StorageIcon}
      list={ServerList}
      edit={ServerEdit}
      create={ServerCreate}
    />
    <Resource
      name="payment-methods"
      list={ListGuesser}
      icon={PaymentIcon}
    />
  </Admin>
);

export default App;
