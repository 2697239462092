import {
  Create,
  SimpleForm,
  SelectInput,
  TextInput,
} from 'ra-ui-materialui';
import React from 'react';
import gameChoices from '../../services/gameChoices';
import regionChoices from '../../services/regionChoices';

const ServerCreate: React.FunctionComponent = (props) => (
  <Create {...props}>
    <SimpleForm>
      <SelectInput source="game" choices={gameChoices} defaultValue={gameChoices[0].id} />
      <TextInput source="name" />
      <SelectInput source="region" choices={regionChoices} defaultValue={regionChoices[2].id} />
      <TextInput source="settings.password" label="Password" />
    </SimpleForm>
  </Create>

);

export default ServerCreate;
