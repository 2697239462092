import { createReducer } from '@reduxjs/toolkit';
import { LoginPages, LoginPageState } from '../../types/state';
import {
  setLoginPageEmail,
  setLoginPageToForgotPassword,
  setLoginPageToLogin,
  setLoginPageToRegister,
  setLoginPageToVerify,
  setLoginPageToVerifyForgotPassword,
} from '../actions';

/* eslint-disable no-param-reassign */
// redux-toolkit uses the param assigment syntax and handles immutability internally https://redux-toolkit.js.org/api/createReducer#example-usage

const initialLoginPageState: LoginPageState = {
  currentPage: LoginPages.LOGIN,
  email: '',
};

export const loginPageReducer = createReducer(initialLoginPageState, (builder) => {
  builder
    .addCase(setLoginPageToLogin, (state) => {
      state.currentPage = LoginPages.LOGIN;
    })
    .addCase(setLoginPageToForgotPassword, (state) => {
      state.currentPage = LoginPages.FORGOT_PASSWORD;
    })
    .addCase(setLoginPageToVerifyForgotPassword, (state) => {
      state.currentPage = LoginPages.VERIFY_FORGOT_PASSWORD;
    })
    .addCase(setLoginPageToRegister, (state) => {
      state.currentPage = LoginPages.REGISTER;
    })
    .addCase(setLoginPageToVerify, (state) => {
      state.currentPage = LoginPages.VERIFY;
    })
    .addCase(setLoginPageEmail, (state, action) => {
      state.email = action.payload;
    });
});
