import { Card, createMuiTheme, makeStyles } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';
import { defaultTheme, Notification } from 'ra-ui-materialui';
import React from 'react';
import { useSelector } from 'react-redux';
import { CustomReduxState, LoginPages } from '../../types/state';
import ForgotPasswordPage from './ForgotPasswordPage';
import ForgotPasswordVerifyPage from './ForgotPasswordVerifyPage';
import LoginPage from './LoginPage';
import RegisterPage from './RegisterPage';
import VerificationPage from './VerificationPage';

const styles = makeStyles({
  background: {
    minHeight: '100vh',
    backgroundColor: '#6161e0',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  card: {
    width: '90vw',
    maxWidth: '400px',
    // transform: 'translateY(-200px)',
    padding: '14px',
  },
});

const LoginRouter: React.FunctionComponent = () => {
  const loginPageState = useSelector((state: CustomReduxState) => state.loginPage);
  const classes = styles();
  let LoginPageComponent: React.ReactElement;

  switch (loginPageState.currentPage) {
    case LoginPages.FORGOT_PASSWORD:
      LoginPageComponent = <ForgotPasswordPage />;
      break;
    case LoginPages.VERIFY_FORGOT_PASSWORD:
      LoginPageComponent = <ForgotPasswordVerifyPage />;
      break;
    case LoginPages.VERIFY:
      LoginPageComponent = <VerificationPage />;
      break;
    case LoginPages.REGISTER:
      LoginPageComponent = <RegisterPage />;
      break;
    default:
      LoginPageComponent = <LoginPage />;
      break;
  }

  return (
    <ThemeProvider theme={createMuiTheme(defaultTheme)}>
      <div className={classes.background}>
        <Card className={classes.card}>
          {LoginPageComponent}
          <Notification />
        </Card>
      </div>
    </ThemeProvider>
  );
};

export default LoginRouter;
