import {
  DateField,
  Edit,
  EditProps,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
} from 'ra-ui-materialui';
import React from 'react';
import regionChoices from '../../services/regionChoices';

const ServerEdit: React.FunctionComponent<EditProps> = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <TextField source="id" />
      <TextField source="game" />
      <DateField source="createdOn" />
      <TextInput source="name" />
      <SelectInput source="region" choices={regionChoices} />
      <TextInput source="settings.password" label="Password" />
    </SimpleForm>
  </Edit>
);

export default ServerEdit;
