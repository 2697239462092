function getFetchRequestHeaders(): HeadersInit {
  return {
    'Content-Type': 'application/json',
    Authorization: localStorage.getItem('token') || '',
  };
}

export async function startServer(serverId: string): Promise<void> {
  const serverUrl = process.env.SERVER_URL || '';
  await fetch(`${serverUrl}/user/servers/${serverId}/start`, {
    headers: getFetchRequestHeaders(),
  });
}

export async function stopServer(serverId: string): Promise<void> {
  const serverUrl = process.env.SERVER_URL || '';
  await fetch(`${serverUrl}/user/servers/${serverId}/stop`, {
    headers: getFetchRequestHeaders(),
  });
}
