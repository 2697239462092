import { CognitoUser, CognitoUserPool } from 'amazon-cognito-identity-js';

export function getCognitoUserPool(): CognitoUserPool {
  const poolId = process.env.AWS_COGNITO_USER_POOL_ID;
  const clientId = process.env.AWS_COGNITO_CLIENT_ID;

  if (!poolId) {
    throw new Error('Expected environment variable AWS_COGNITO_USER_POOL_ID is not set');
  }

  if (!clientId) {
    throw new Error('Expected environment variable AWS_COGNITO_CLIENT_ID is not set');
  }

  return new CognitoUserPool({
    UserPoolId: poolId,
    ClientId: clientId,
  });
}

export function getCognitoUser(userPool: CognitoUserPool, email: string): CognitoUser {
  return new CognitoUser({
    Username: email,
    Pool: userPool,
  });
}
