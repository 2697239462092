import {
  CreateButton,
  Datagrid,
  DateField,
  List,
  ListProps,
  sanitizeListRestProps,
  SelectField,
  TextField,
  TopToolbar,
  useListContext,
} from 'react-admin';
import React, { cloneElement } from 'react';
import ServerStatusField from './ServerStatusField';
import ServerPasswordField from './ServerPasswordField';
import regionChoices from '../../services/regionChoices';
import gameChoices from '../../services/gameChoices';
import ServerStartStopButton from './ServerStartStopButton';
import ServerEditButton from './ServerEditButton';

const ServerActions: React.FunctionComponent = (props: any) => {
  const {
    className,
    exporter,
    filters,
    maxResults,
    ...rest
  } = props;

  const {
    resource,
    displayedFilters,
    filterValues,
    basePath,
    showFilter,
  } = useListContext();

  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters && cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: 'button',
      })}
      <CreateButton basePath={basePath} />
    </TopToolbar>
  );
};

const ServerList: React.FunctionComponent<ListProps> = (props) => (
  <List
    {...props}
    actions={<ServerActions />}
    sort={{ field: 'createdOn', order: 'ASC' }}
  >
    <Datagrid>
      <TextField source="id" label="Server ID" />
      <TextField source="name" />
      <SelectField source="game" choices={gameChoices} />
      <ServerStatusField source="status" />
      <DateField source="createdOn" />
      <SelectField source="region" choices={regionChoices} />
      <ServerPasswordField source="settings.password" label="Password" />
      <ServerEditButton />
      <ServerStartStopButton />
    </Datagrid>
  </List>
);

export default ServerList;
