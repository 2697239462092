import { ReduxState } from 'ra-core';

export enum LoginPages {
  FORGOT_PASSWORD,
  VERIFY_FORGOT_PASSWORD,
  LOGIN,
  REGISTER,
  VERIFY,
}

export interface LoginPageState {
  currentPage: LoginPages;
  email: string;
}

export interface CustomReduxState extends ReduxState {
  loginPage: LoginPageState;
}
