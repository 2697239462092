import { makeStyles } from '@material-ui/core';

export const centerAlignSideFlexStyle = makeStyles({
  centerAlignSideFlex: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',

    '& > * + *': {
      marginLeft: '4px',
    },
  },
});
