import React from 'react';
import { useNotify, useRefresh } from 'ra-core';
import { Button } from 'ra-ui-materialui';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import StopIcon from '@material-ui/icons/Stop';
import { OverridableComponent } from '@material-ui/core/OverridableComponent';
import { SvgIconTypeMap } from '@material-ui/core';
import { GameServerResponse } from '../../types/serverResponse';
import { CustomFieldProps } from '../commonTypes';
import { startServer, stopServer } from '../../services/gameServer';

const ServerStartStopButton: React.FunctionComponent<CustomFieldProps> = ({
  record,
}) => {
  const { status, id } = (record as GameServerResponse);
  const notify = useNotify();
  const refresh = useRefresh();
  let Icon: OverridableComponent<SvgIconTypeMap>;
  let disabled = false;
  let label = 'Start';

  switch (status) {
    case 'off':
      Icon = PowerSettingsNewIcon;
      break;
    case 'shutting-down':
      Icon = PowerSettingsNewIcon;
      disabled = true;
      break;
    case 'running':
      Icon = StopIcon;
      label = 'Stop';
      break;
    case 'starting':
      Icon = StopIcon;
      disabled = true;
      label = 'Stop';
      break;
    default:
      Icon = PowerSettingsNewIcon;
      disabled = true;
  }

  const handleClick = async () => {
    if (!disabled) {
      if (status === 'off') {
        await startServer(id);
        notify('Starting server');
        refresh();
      } else if (status === 'running') {
        await stopServer(id);
        notify('Stopping server');
        refresh();
      }
    }
  };
  return (
    <Button label={label} color="primary" disabled={disabled} onClick={handleClick}>
      <Icon />
    </Button>
  );
};

export default ServerStartStopButton;
