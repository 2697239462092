import {
  Button, createMuiTheme, Link, makeStyles, TextField, Typography,
} from '@material-ui/core';
import { useFormik } from 'formik';
import { useNotify } from 'ra-core';
import { defaultTheme } from 'ra-ui-materialui';
import React from 'react';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';
import { signUp } from '../../../services/auth';
import { setLoginPageEmail, setLoginPageToLogin, setLoginPageToVerify } from '../../../state/actions';

const theme = createMuiTheme(defaultTheme);

const styles = makeStyles({
  form: {
    display: 'flex',
    flexDirection: 'column',

    '& > * + *': {
      marginTop: '12px',
    },
  },
  forgotPassword: {
    textAlign: 'right',
  },
  loginButton: {
    marginTop: '24px',
  },
  login: {
    ...theme.typography.body2,
    textAlign: 'center',
  },
});

const validationSchema = yup.object().shape({
  email: yup
    .string()
    .email('Email is not valid')
    .required('Email is required'),
  password: yup
    .string()
    .min(8, 'Password should be longer than 8 characters')
    .matches(/[a-z]/, 'Password should contain at least one lowercase letter')
    .matches(/[A-Z]/, 'Password should contain at least one uppercase letter')
    .matches(/\d/, 'Password should contain at least one number')
    .required('Password is requried'),
});

/* eslint-disable jsx-a11y/anchor-is-valid */
// Link components with a component="button" will use button

const RegisterPage: React.FunctionComponent = () => {
  const notify = useNotify();
  const classes = styles();
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    onSubmit: async (values) => {
      try {
        await signUp(values.email, values.password);
        dispatch(setLoginPageEmail(values.email));
        dispatch(setLoginPageToVerify());
      } catch (error) {
        notify(error.message);
      }
    },
    validationSchema,
  });

  return (
    <>
      <form className={classes.form} onSubmit={formik.handleSubmit}>
        <Typography variant="h5">Create an account</Typography>
        <Typography variant="body2">
          When you register a verification code will be send
          to the email address provided
        </Typography>
        <TextField
          label="Email"
          value={formik.values.email}
          onChange={formik.handleChange('email')}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
        />
        <TextField
          label="Password"
          type="password"
          value={formik.values.password}
          onChange={formik.handleChange('password')}
          error={formik.touched.password && Boolean(formik.errors.password)}
          helperText={formik.touched.password && formik.errors.password}
        />
        <Button className={classes.loginButton} variant="contained" color="primary" type="submit">
          Register
        </Button>
      </form>
      <p className={classes.login}>
        Already have an account?&nbsp;
        <Link
          component="button"
          onClick={() => dispatch(setLoginPageToLogin())}
        >
          Login
        </Link>
      </p>
    </>
  );
};

export default RegisterPage;
