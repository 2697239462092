import { EditButton } from 'ra-ui-materialui';
import React from 'react';
import { GameServerResponse } from '../../types/serverResponse';
import { CustomFieldProps } from '../commonTypes';

const ServerEditButton: React.FunctionComponent<CustomFieldProps> = ({
  basePath,
  record,
}) => {
  const { status } = (record as GameServerResponse);
  let disabled = false;

  switch (status) {
    case 'running':
    case 'shutting-down':
    case 'starting':
      disabled = true;
      break;
    case 'off':
    default:
      break;
  }
  return (
    <EditButton basePath={basePath} disabled={disabled} record={record} />
  );
};

export default ServerEditButton;
