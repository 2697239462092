import {
  Button, makeStyles, TextField, Typography,
} from '@material-ui/core';
import { useFormik } from 'formik';
import React from 'react';
import * as yup from 'yup';
import { useDispatch } from 'react-redux';
import { useNotify } from 'ra-core';
import { verify } from '../../../services/auth';
import { useCustomLogin } from '../../../state/selectors';
import { setLoginPageToLogin } from '../../../state/actions';

const styles = makeStyles({
  form: {
    display: 'flex',
    flexDirection: 'column',
    '& > * + *': {
      marginTop: '12px',
    },
  },
  button: {
    marginTop: '24px',
  },
});

const validationSchema = yup.object().shape({
  validationCode: yup.number().required('Validation code is required'),
});

const VerificationPage: React.FunctionComponent = () => {
  const notify = useNotify();
  const customLogin = useCustomLogin();
  const dispatch = useDispatch();
  const classes = styles();
  const formik = useFormik({
    initialValues: {
      validationCode: '',
    },
    onSubmit: async (values) => {
      try {
        await verify(customLogin.email, values.validationCode);
        dispatch(setLoginPageToLogin());
      } catch (error) {
        notify(error.message);
      }
    },
    validationSchema,
  });

  return (
    <>
      <form className={classes.form} onSubmit={formik.handleSubmit}>
        <Typography variant="h5">Verify you email</Typography>
        <TextField
          label="Validation code"
          value={formik.values.validationCode}
          onChange={formik.handleChange('validationCode')}
          error={formik.touched.validationCode && Boolean(formik.errors.validationCode)}
          helperText={formik.touched.validationCode && formik.errors.validationCode}
        />
        <Button className={classes.button} variant="contained" color="primary" type="submit">
          Validate
        </Button>
      </form>
    </>
  );
};

export default VerificationPage;
